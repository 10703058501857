class GA4 {

    trackUpload() {
        this.sendEvent('photo_upload', {});
    }

    trackDownload(brand, frameKey, frameName, frameType, frameTheme, aspect_ratio) {
        this.sendEvent('photo_download', {
            brand,
            frame: frameKey,
            frame_name: frameName,
            frame_type: frameType,
            frame_theme: frameTheme,
            aspect_ratio
        });
    }

    async sendEvent(name, params) {
        if (!PRODUCTION) {
            params.debug_mode = true;
            console.log(`GA events ${name}`, params);
        }

        gtag('event', name, params);
    }

}

export default new GA4;